import { RecipeItem } from "../components/shared/RecipeCard";
import { getAssetURL } from "./assets";
import { camelCase, mapKeys } from "lodash";
import { getTranslations } from "./translations";
import { isPublished } from "./directus";
import { ROUTES } from "./routes";

const CREATED_BY_VARIANTS = ["CREATED BY:", "Created By:", "created by:"];

export const getAuthor = (recipeAuthor, commonLabels) => {
  let author = recipeAuthor || "";
  CREATED_BY_VARIANTS.forEach((label) => {
    author.replace(label, "");
  });
  author = author.trim();
  if (author && commonLabels?.createdByLabel) {
    return `${commonLabels?.createdByLabel}: ${author}`;
  }
  if (author) {
    return author;
  }
  return null;
};

export const mapRecipeCard = (recipe, commonLabels, language): RecipeItem[] => {
  const translatedMainSection = getTranslations(
    recipe,
    `translations`,
    language
  );

  const translatedRecipe = getTranslations(
    translatedMainSection,
    `recipe.translations`,
    language
  );

  const translatedRecipeProduct = getTranslations(
    translatedMainSection,
    `recipe.product_page.translations`,
    language
  );

  const translatedProduct = getTranslations(
    translatedRecipeProduct,
    `product.translations`,
    language
  );

  const fMainSection = mapKeys(
    translatedRecipe || translatedMainSection,
    (_v, k) => camelCase(k)
  ) as RecipeItem;

  return {
    status:
      translatedMainSection?.status ||
      translatedMainSection?.recipe_content_status,
    author: getAuthor(fMainSection?.author, commonLabels),
    bottomLeftLabel: commonLabels?.servesLabel,
    bottomLeftValue: fMainSection?.servesValue,
    bottomMidLabel: commonLabels?.prepTimeLabel,
    bottomMidValue: fMainSection?.prepTimeValue,
    bottomRightLabel: commonLabels?.cookTimeLabel,
    bottomRightValue: fMainSection?.cookTimeValue,
    date: recipe?.date_created,
    title: fMainSection?.title,
    subtitle: translatedProduct?.product_name,
    tags: fMainSection?.tags,
    image: {
      src: getAssetURL(fMainSection?.image),
      alt: fMainSection?.imageAlt,
    },
    recipeDetail: {
      recipeDetailSlug: recipe?.recipe_page_slug,
    },
  };
};

export const mapSideRecipes = (recipes: any, intl: any, labels: any) => {
  return recipes
    ?.map(({ recipe_page_id }) => {
      const { recipe } = getTranslations(
        recipe_page_id,
        "translations",
        intl.locale
      ) || {};
      const { title, ...rest } = getTranslations(
        recipe,
        "translations",
        intl.locale
      ) || {};
      if(!title){console.log({intl, recipe})}
      return {
        ...rest,
        name: title?.toLowerCase(),
        button_label: labels?.viewRecipe?.toLowerCase(),
        button_url: `${ROUTES.RECIPE_DETAIL}/${recipe_page_id?.recipe_page_slug}`,
      };
    })
    ?.filter(({ status }: { status: string }) => isPublished(status));
};
