import { graphql } from "gatsby";
import * as React from "react";
import { useCallback } from "react";
import { parseSEO } from "../helpers/seo";
import {
  HomeMainCarousel,
  HomeMainCarouselProps,
} from "../components/HomeMainCarousel";
import { Ingredients, IngredientsProps } from "../components/Ingredients";
import {
  OurCommunityProps,
  OurCommunitySection,
} from "../components/OurCommunity";
import { OurMission, OurMissionProps } from "../components/OurMission";
import Page from "../components/Page";
import { Partners, PartnersProps } from "../components/Partners";
import {
  ProductCarousel,
  ProductCarouselProps,
} from "../components/ProductCarousel";
import Recipes, { RecipesProps } from "../components/Recipes/Recipes";

import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import { isPublished } from "../helpers/directus";
import { mapSideRecipes } from "../helpers/recipe";
import LocatorCarousel, {
  LocatorCarouselProps,
  LocatorCarouselSlide,
} from "../components/LocatorCarousel/LocatorCarousel";
import SEO from "../components/SEO";
import { useIntl } from "../hooks/useIntl";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { ROUTES } from "../helpers/routes";

const IndexPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return (
      getTranslations(data, "directus.home_page.translations", intl.locale) ??
      {}
    );
  }, [data]);

  const getIngredientsData = useCallback((): IngredientsProps => {
    const translatedData = getRootTranslatedData();
    return {
      assets: {
        image1: {
          src: getAssetURL(translatedData?.ingredients_small_image),
          alt: translatedData?.ingredients_small_image_alt,
        },
        image2: {
          src: getAssetURL(translatedData?.ingredients_large_image),
          alt: translatedData?.ingredients_large_image_alt,
        },
        image3: {
          src: getAssetURL(translatedData?.ingredients_circular_image),
          alt: translatedData?.ingredients_circular_image_alt,
        },
      },
      labels: {
        mainTitle: translatedData?.ingredients_background_title,
        title: translatedData?.ingredients_title,
        subtitle: translatedData?.ingredients_eyebrow,
        description: translatedData?.ingredients_description,
        buttonLabel: translatedData?.ingredients_button_label,
        buttonLink: ROUTES.INGREDIENTS,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getHeroCarouselData = useCallback((): HomeMainCarouselProps => {
    const translatedData = getRootTranslatedData();

    return {
      slides: isPublished(translatedData?.hero_carousel_status)
        ? (translatedData?.slides || [])
            .map(
              ({
                hero_carousel_slides_id,
              }: {
                hero_carousel_slides_id: any;
              }) => {
                const translatedSlide = getTranslations(
                  hero_carousel_slides_id,
                  "translations",
                  intl.locale
                );

                return {
                  status: translatedSlide?.status,
                  assets: {
                    smallImage: {
                      src: getAssetURL(translatedSlide?.small_image),
                      alt: translatedSlide?.small_image_alt,
                    },
                    largeImage: {
                      src: getAssetURL(translatedSlide?.large_image),
                      alt: translatedSlide?.large_image_alt,
                    },
                  },
                  labels: {
                    headerText: translatedSlide?.header,
                    primaryButtonOverrideLabel: translatedSlide?.shop_now_label_override,
                    primaryButtonDefaultLabel: labels?.viewNow,
                    primaryButtonLink: translatedSlide?.shop_now_link,
                    secondaryButtonOverrideLabel: translatedSlide?.find_near_label_override,
                    secondaryButtonDefaultLabel: labels?.findNearMe,
                    secondaryButtonLink: translatedSlide?.find_near_me_link,
                    description: translatedSlide?.description,
                  },
                };
              }
            )
            ?.filter(({ status }: { status: string }) => isPublished(status))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  const getProductCarouselData = useCallback((): ProductCarouselProps => {
    const translatedData = getRootTranslatedData();

    return {
      slides: isPublished(translatedData?.product_carousel_status)
        ? (translatedData?.products_slides || [])
            .map(({ product_carousel_slides_id }) => {
              const translatedSlide = getTranslations(
                product_carousel_slides_id,
                "translations",
                intl.locale
              );

              const { product } =
                getTranslations(
                  translatedSlide,
                  "product_page.translations",
                  intl.locale
                ) ?? {};

              const translatedProduct = getTranslations(
                product,
                "translations",
                intl.locale
              );

              const variants = isPublished(translatedProduct?.status)
                ? translatedProduct?.variants ?? []
                : [];

              const [firstVariant = {}] = variants
                ?.map(
                  ({ product_variant_id }) =>
                    getTranslations(
                      product_variant_id,
                      "translations",
                      intl.locale
                    ) ?? {}
                )
                ?.filter((variant: any) => isPublished(variant?.status));

              return {
                status: translatedSlide?.status,
                assets: {
                  smallImage: {
                    src: getAssetURL(firstVariant?.small_image),
                    alt: firstVariant?.small_image_alt,
                  },
                  largeImage: {
                    src: getAssetURL(firstVariant?.large_image),
                    alt: firstVariant?.large_image_alt,
                  },
                },
                labels: {
                  smallText: translatedProduct?.product_name,
                  primaryButtonLabel: labels?.productDetails,
                  primaryButtonLink: `${ROUTES.PRODUCT_OVERVIEW}/${translatedSlide?.product_page?.product_page_slug}`,
                  secondaryButtonLabel: translatedSlide?.secondary_button_label,
                  secondaryButtonLink: translatedSlide?.secondary_button_link,
                  description: translatedSlide?.description,
                },
              };
            })
            ?.filter(({ status }) => isPublished(status))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  const getOurMissionData = useCallback((): OurMissionProps => {
    const translatedData = getRootTranslatedData();

    return {
      status: translatedData?.our_mission_status,
      assets: {
        topPositionImage: {
          src: getAssetURL(translatedData?.top_image),
          alt: translatedData?.top_image_alt,
        },
        middlePositionImage: {
          src: getAssetURL(translatedData?.mid_image),
          alt: translatedData?.mid_image_alt,
        },
        bottomPositionImage: {
          src: getAssetURL(translatedData?.bottom_image),
          alt: translatedData?.bottom_image_alt,
        },
      },
      labels: {
        mainTitle1: translatedData?.our_mission_background_title_1,
        mainTitle2: translatedData?.our_mission_background_title_2,
        title: translatedData?.our_mission_eyebrow,
        subtitle: translatedData?.our_mission_title,
        description: translatedData?.our_mission_description,
        buttonLabel: translatedData?.our_mission_button_label,
        buttonLink: ROUTES.MISSION,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getLocatorCarouselData = useCallback((): LocatorCarouselProps => {
    const translatedData = getRootTranslatedData();

    return {
      title: translatedData?.locator_title,
      eyebrow: translatedData?.locator_eyebrow,
      description: translatedData?.locator_description,
      inputPlaceholder: translatedData?.locator_input_placeholder,
      inputActionLabel: translatedData?.locator_go_label,
      inputActionLink: ROUTES.WHERE_TO_FIND,
      slides: isPublished(translatedData?.locator_carousel_status)
        ? translatedData?.locator_partners
            ?.map((translatedSlide: any): LocatorCarouselSlide => {
              const translatedLocator = getTranslations(
                translatedSlide?.locator_carousel_slides_id,
                "translations",
                intl.locale
              );

              return {
                status: translatedLocator?.status,
                brandName: translatedLocator?.brand_name,
                leftButtonLink: translatedLocator?.order_now_link,
                leftButtonLabel: labels?.orderNow,
                rightButtonLink: translatedLocator?.find_near_me_link,
                rightButtonLabel: labels?.findNearMe,
                brandImage: {
                  src: getAssetURL(translatedLocator?.small_image),
                  alt: translatedLocator?.small_image_alt,
                },
                largeImage: {
                  src: getAssetURL(translatedLocator?.large_image),
                  alt: translatedLocator?.large_image_alt,
                },
              };
            })
            ?.filter(({ status }: { status: string }) => isPublished(status))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  const getOurCommunityData = useCallback((): OurCommunityProps => {
    const translatedData = getRootTranslatedData();

    const ambassadorList = (translatedData?.ambassadors || [])
      .map(({ our_community_ambassador_id }) => {
        const translatedAmbassador = getTranslations(
          our_community_ambassador_id,
          "translations",
          intl.locale
        );
        return {
          ...translatedAmbassador,
          image: getAssetURL(translatedAmbassador?.image),
        };
      })
      ?.filter(({ status }) => isPublished(status));

    return {
      isPublished: isPublished(translatedData?.our_community_status),
      title: translatedData?.our_community_title,
      subtitle: translatedData?.our_community_subtitle,
      description: translatedData?.our_community_description,
      ambassadorList,
    };
  }, [getRootTranslatedData, intl]);

  const getPartnersData = useCallback((): PartnersProps => {
    const translatedData = getRootTranslatedData();

    const partners = translatedData?.partners_list
      ?.map(({ partners_assets_id }) =>
        getTranslations(partners_assets_id, "translations", intl.locale)
      )
      ?.filter((partner: any) => isPublished(partner?.status));

    return {
      isPublished: isPublished(translatedData?.partners_status),
      assets: {
        images: partners,
      },
      labels: {
        title: translatedData?.partners_title,
        description: translatedData?.partners_description,
        buttonLabel: translatedData?.partners_button_label,
        buttonLink: ROUTES.SELL_DETAIL,
        bottomLeft: translatedData?.partners_white_text,
        bottomRightLabel: translatedData?.partners_green_text,
        bottomRightLink: ROUTES.SELL_DETAIL,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getRecipesData = useCallback((): RecipesProps => {
    const translatedData = getRootTranslatedData();

    const leftSideRecipes = mapSideRecipes(
      translatedData?.recipes_left_side,
      intl,
      labels
    );

    const rightSideRecipes = mapSideRecipes(
      translatedData?.recipes_right_side,
      intl,
      labels
    );

    return {
      title: translatedData?.recipes_title,
      subtitle: translatedData?.recipes_description,
      smallText: translatedData?.recipes_eyebrow,
      buttonLabel: translatedData?.recipes_button_label,
      buttonUrl: ROUTES.RECIPES,
      leftImages: leftSideRecipes,
      rightImages: rightSideRecipes,
    };
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#012102"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <HomeMainCarousel {...getHeroCarouselData()}/>
      <OurMission {...getOurMissionData()} />
      <ProductCarousel {...getProductCarouselData()} />
      <Ingredients {...getIngredientsData()} />
      <Recipes {...getRecipesData()} />
      <LocatorCarousel {...getLocatorCarouselData()} />
      <OurCommunitySection {...getOurCommunityData()} />
      <Partners {...getPartnersData()} />
    </Page>
  );
};

export default IndexPage;

export const query = graphql`
  query ($locale: String) {
    directus {
      home_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            name
            code
          }
          status
          hero_carousel_status
          slides {
            hero_carousel_slides_id {
              name
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                status
                large_image_alt
                small_image_alt
                languages_code {
                  name
                  code
                }
                id
                large_image {
                  id
                  filename_disk
                }
                small_image {
                  id
                  filename_disk
                }
                shop_now_label_override
                find_near_label_override
                shop_now_link
                description
                header
                find_near_me_link
              }
            }
          }
          our_mission_status
          our_mission_title
          our_mission_eyebrow
          our_mission_background_title_1
          our_mission_background_title_2
          our_mission_description
          our_mission_button_label
          top_image {
            id
            filename_disk
          }
          top_image_alt
          mid_image {
            id
            filename_disk
          }
          mid_image_alt
          bottom_image {
            id
            filename_disk
          }
          bottom_image_alt
          product_carousel_status
          products_slides {
            product_carousel_slides_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                secondary_button_label
                status
                languages_code {
                  name
                  code
                }
                id
                secondary_button_link
                description
                product_page {
                  product_page_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      name
                      code
                    }
                    product {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        status
                        product_name
                        languages_code {
                          name
                          code
                        }
                        variants {
                          product_variant_id {
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              status
                              languages_code {
                                name
                                code
                              }
                              subtitle
                              small_image_alt
                              small_image {
                                id
                                filename_disk
                              }
                              large_image_alt
                              large_image {
                                id
                                filename_disk
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          recipes_status
          recipes_title
          recipes_eyebrow
          recipes_description
          recipes_button_label
          recipes_left_side {
            recipe_page_id {
              recipe_page_slug
              id
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                status
                recipe_content_status
                recipe {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      name
                      code
                    }
                    title
                    status
                    image_alt
                    image {
                      id
                      filename_disk
                    }
                  }
                }
              }
            }
          }
          recipes_right_side {
            recipe_page_id {
              recipe_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                status
                recipe_content_status
                recipe {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      name
                      code
                    }
                    title
                    status
                    image_alt
                    image {
                      id
                      filename_disk
                    }
                  }
                }
              }
            }
          }
          locator_carousel_status
          locator_title
          locator_eyebrow
          locator_description
          locator_input_placeholder
          locator_go_label
          locator_partners {
            locator_carousel_slides_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                large_image_alt
                small_image_alt
                brand_name
                status
                languages_code {
                  name
                  code
                }
                id
                small_image {
                  id
                  filename_disk
                }
                find_near_me_link
                order_now_link
                large_image {
                  id
                  filename_disk
                }
              }
            }
          }
          our_community_status
          our_community_title
          our_community_subtitle
          our_community_description
          ambassadors {
            our_community_ambassador_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                status
                image_alt
                last_name
                first_name
                languages_code {
                  name
                  code
                }
                id
                quote
                link
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          partners_status
          partners_title
          partners_description
          partners_button_label
          partners_white_text
          partners_green_text
          partners_list {
            partners_assets_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                status
                image_alt
                languages_code {
                  name
                  code
                }
                id
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          ingredients_status
          ingredients_background_title
          ingredients_eyebrow
          ingredients_title
          ingredients_description
          ingredients_button_label
          ingredients_small_image {
            id
            filename_disk
          }
          ingredients_small_image_alt
          ingredients_large_image {
            id
            filename_disk
          }
          ingredients_circular_image {
            id
            filename_disk
          }
          ingredients_circular_image_alt
        }
      }
    }
  }
`;

// export const query = graphql`
//   query ($locale: String) {
//     directus {
//       home_page {
//         seo {
//           ...SEO
//         }
//         our_mission_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             main_title_1
//             main_title_2
//             title
//             subtitle
//             description
//             button_label
//             button_link
//             top_position_image {
//               id
//               filename_disk
//             }
//             top_position_image_alt
//             middle_position_image {
//               id
//               filename_disk
//             }
//             middle_position_image_alt
//             bottom_position_image {
//               id
//               filename_disk
//             }
//             bottom_position_image_alt
//           }
//         }
//         hero_carousel_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             hero_carousel_slides {
//               header_text_white
//               header_text_black
//               primary_button_label
//               primary_button_link
//               secondary_button_label
//               secondary_button_link
//               description
//               large_image {
//                 id
//                 filename_disk
//               }
//               large_image_alt
//               small_image {
//                 id
//                 filename_disk
//               }
//               small_image_alt
//             }
//           }
//         }
//         product_carousel_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             product_carousel_slides {
//               small_text
//               primary_button_label
//               primary_button_link
//               secondary_button_label
//               secondary_button_link
//               description
//               large_image {
//                 id
//                 filename_disk
//               }
//               large_image_alt
//               small_image {
//                 id
//                 filename_disk
//               }
//               small_image_alt
//             }
//           }
//         }
//         locator_carousel_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             locator_carousel_slides {
//               brand_image {
//                 id
//                 filename_disk
//               }
//               brand_image_alt
//               brand_name
//               heading
//               input_placeholder
//               input_action_label
//               input_action_link
//               large_image {
//                 id
//                 filename_disk
//               }
//               large_image_alt
//               left_button_label
//               left_button_link
//               right_button_label
//               right_button_link
//               small_text
//               subheading
//             }
//           }
//         }
//         ingredients_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             title
//             main_title
//             subtitle
//             description
//             button_label
//             button_link
//             image_1 {
//               id
//               filename_disk
//             }
//             image_1_alt
//             image_2 {
//               id
//               filename_disk
//             }
//             image_2_alt
//             image_3 {
//               id
//               filename_disk
//             }
//             image_3_alt
//           }
//         }
//         recipes_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             title
//             small_text
//             subtitle
//             button_label
//             button_url
//             left_images {
//               name
//               image {
//                 id
//                 filename_disk
//               }
//               image_alt
//               button_label
//               button_url
//             }
//             right_images {
//               name
//               image {
//                 id
//                 filename_disk
//               }
//               image_alt
//               button_label
//               button_url
//             }
//           }
//         }
//         our_community_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             title
//             subtitle
//             description
//             ambassador_list {
//               first_name
//               last_name
//               quote
//               image {
//                 id
//                 filename_disk
//               }
//               image_alt
//               link
//             }
//           }
//         }
//         partners_section {
//           translations(filter: { languages_code: { code: { _eq: $locale } } }) {
//             languages_code {
//               name
//               code
//             }
//             title
//             description
//             button_label
//             button_link
//             partner_list {
//               image {
//                 id
//                 filename_disk
//               }
//               image_alt
//             }
//             bottom_left_text
//             bottom_right_text_label
//             bottom_right_text_link
//           }
//         }
//       }
//     }
//   }
// `;
