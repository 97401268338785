import React, { ReactElement } from "react";
import styled, { Keyframes, keyframes } from "styled-components";
import { vwDesktop } from "../../helpers/styles";
import Item from "./Item";
import { RecipeImage } from "./Recipes";

type SliderProps = {
  height: string;
  itemWidth: string;
  itemHeight: string;
  scroll: "up" | "down";
  images: RecipeImage[];
};

export default function VerticalSlider(props: SliderProps): ReactElement {
  const { itemWidth, itemHeight, images } = props;
  const trackRef = React.useRef<HTMLDivElement>(null);

  const list = (images || []).map((image, index) => (
    <Item width={itemWidth} height={itemHeight} image={image} key={index} />
  ));

  return (
    <Container height={props.height}>
      <Track ref={trackRef} {...props}>
        {list}
        {list}
      </Track>
    </Container>
  );
}

const animation = (direction: "up" | "down"): Keyframes => {
  switch (direction) {
    case "up":
      return keyframes`0% { transform: translateY(-50%); } 100% { transform: translateY(0%); }`;
    case "down":
      return keyframes`0% { transform: translateY( 0% ); } 100% { transform: translateY(-50%); }`;
  }
};

const Container = styled.div<{ height: number | string }>`
  overflow: hidden;
  max-height: ${(p) => p.height};
`;

const Track = styled.div<SliderProps>`
  display: flex;
  flex-flow: column;
  gap: ${vwDesktop(20)};
  animation-name: ${(p) => animation(p.scroll)};
  animation-duration: ${(p) => (p?.images?.length || 1) * 10 * 1000}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:hover {
    animation-play-state: paused;
  }
`;
