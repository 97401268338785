import { useMediaQueries } from "@react-hook/media-query";
import React, { ReactNode } from "react";
import { MEDIA } from "../../helpers/theme";

type MediaProps = {
  on: "mobile" | "desktop";
  children: ReactNode;
};

/**
 * Show JSX depending if screen is mobile or desktop
 */
export default function Media({
  on,
  children,
}: MediaProps): JSX.Element | null {
  const { matches } = useMediaQueries({
    mobile: MEDIA.MOBILE,
    desktop: MEDIA.DESKTOP,
  });

  const output = <>{children}</>;
  if (!matches[on]) {
    return null;
  }
  return output;
}
