import React from "react";
import styled from "styled-components";
import { ProductImage } from "../../helpers/shopify";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import ButtonLink from "../shared/ButtonLink";
import Carousel from "./Carousel";
import Media from "./Media";
import VerticalSlider from "./VerticalSlider";

export type Recipe = {
  image: ProductImage;
  name: string;
  linkUrl?: string;
};

export type RecipesProps = {
  title: string;
  subtitle: string;
  smallText: string;
  buttonLabel: string;
  buttonUrl: string;
  leftImages: RecipeImage[];
  rightImages: RecipeImage[];
};

export type RecipeImage = {
  name: string;
  image: string;
  image_alt: string;
  button_label: string;
  button_url: string;
};

export default function Recipes({
  title,
  subtitle,
  smallText,
  leftImages,
  rightImages,
  buttonLabel = "View All Recipes",
  buttonUrl,
}: RecipesProps): JSX.Element {
  return (
    <Section>
      <Container>
        <Media on="mobile">
          <Carousel
            images={leftImages}
            itemWidth={vwMobile(270)}
            itemHeight={vwMobile(360)}
          />
        </Media>
        <Media on="desktop">
          <VerticalSlider
            itemWidth={vwDesktop(400)}
            itemHeight={vwDesktop(400)}
            height={vwDesktop(600)}
            scroll="up"
            {...{ images: leftImages }}
          />
        </Media>
        <Header>
          <Eyebrow>{smallText}</Eyebrow>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
          <Subtext>{subtitle}</Subtext>
          <div>
            <ActionButton link={buttonUrl} label={buttonLabel} />
          </div>
        </Header>
        <Media on="desktop">
          <VerticalSlider
            itemWidth={vwDesktop(295)}
            itemHeight={vwDesktop(295)}
            height={vwDesktop(600)}
            scroll="down"
            {...{ images: rightImages }}
          />
        </Media>
      </Container>
    </Section>
  );
}

const Section = styled.section`
  background-color: #304f4c;
  ${MEDIA_DESKTOP} {
    padding-block: ${vwDesktop(80)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: ${vwMobile(60)};

  padding: ${vwMobile(40)} 0;

  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;

  background-color: #678e8b;

  ${MEDIA_DESKTOP} {
    flex-flow: row;
    align-items: center;
    width: ${vwDesktop(1240)};
    padding: ${vwDesktop(20)};
    gap: ${vwDesktop(60)};
    margin: auto;
  }
`;

const Header = styled.header`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0 ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    padding: 0;
  }
`;

const Eyebrow = styled.div`
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: ${vwMobile(9)};
  letter-spacing: 0.08em;
  color: #000000;
  ${MEDIA_DESKTOP} {
    font-size: ${rem(18)};
    margin-bottom: ${vwDesktop(8)};
    line-height: ${rem(24)};
  }
`;

const Heading = styled.div`
  font-size: ${rem(48)};
  font-weight: 900;
  line-height: ${rem(56)};
  letter-spacing: 0.03em;
  margin-bottom: ${vwMobile(15)};
  color: #000000;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(56)};
    line-height: ${rem(64)};
    margin-bottom: ${vwDesktop(8)};
  }
`;

const Subtext = styled.div`
  font-size: ${rem(18)};
  font-weight: 600;
  margin-bottom: ${vwMobile(41)};
  color: #000000;
  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(40)};
  }
`;

const ActionButton = styled(ButtonLink)``;
