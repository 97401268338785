import React, { FC, useState, useMemo, useCallback, useRef } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import { Parallax } from "react-scroll-parallax";
import { getLimits } from "../../helpers/parallax";
import { flatMap } from "lodash";
import { TranslatableComponent, Image as ImageType } from "../../types";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwMobile,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import AnimatedCarousel from "../shared/AnimatedCarousel";
import { AnimatedCarouselSlide } from "../shared/AnimatedCarouselSlide";
import { ButtonLink, ButtonType } from "../shared/ButtonLink";
import { CarouselDots } from "../shared/Carousel";
import ImagesPreloader from "../shared/ImagesPreloader";
import PlayButton, {
  PlayButtonRef,
} from "../shared/PlayArrowButton/PlayArrowButton";
import Playhead from "../shared/PlayButton/PlayButton";
import useRightToLeft from "../../hooks/useRightToLeft";

type HomeMainCarouselSlideAssets = {
  smallImage: ImageType;
  largeImage: ImageType;
};

type HomeMainCarouselSlideLabels = {
  headerText: string;
  primaryButtonOverrideLabel: string;
  primaryButtonDefaultLabel: string;
  primaryButtonLink: string;
  secondaryButtonOverrideLabel: string;
  secondaryButtonDefaultLabel: string;
  secondaryButtonLink: string;
  description: string;
};

type HomeMainCarouselSlideProps = TranslatableComponent<
  HomeMainCarouselSlideAssets,
  HomeMainCarouselSlideLabels
>;

export type HomeMainCarouselProps = {
  slides: HomeMainCarouselSlideProps[];
};

const LEFT_CAROUSEL_ID = "MAIN_CAROUSEL_LEFT";
const RIGHT_CAROUSEL_ID = "MAIN_CAROUSEL_RIGHT";

const HomeMainCarousel: FC<HomeMainCarouselProps> = ({
  slides,
}: HomeMainCarouselProps) => {
  if (!slides?.length) return null;

  const [currentSlide, setCurrentSlide] = useState(0);
  const playButtonRef = useRef<PlayButtonRef>(null);
  const [paused, setPaused] = useState(false);
  const isRTL = useRightToLeft();

  const handleSlideChange = useCallback((slideIndex: number): void => {
    setCurrentSlide(slideIndex);
    playButtonRef.current?.reset();
  }, []);

  const BigImageAnimationConfig = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: { opacity: 0, delay: 1000 },
    };
  }, []);

  const DishAnimationConfig = useMemo(() => {
    return {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    };
  }, []);

  return (
    <Container>
      <MarqueeWrapper>
        <Marquee
          direction={isRTL ? "right" : "left"}
          speed={50}
          gradient={false}
        >
          <Parallax y={getLimits(["-25px", "12.5px"], ["-50px", "25px"])}>
            <HeaderText
              dangerouslySetInnerHTML={{
                __html: slides[currentSlide]?.labels?.headerText,
              }}
            />
          </Parallax>
        </Marquee>
      </MarqueeWrapper>
      <MainContent>
        <BigImageWrapper>
          <AnimatedBigImage
            id={RIGHT_CAROUSEL_ID}
            aria-live="off"
            elements={slides?.map((s, i) => (
              <StyledAnimatedCarouselSlide
                key={i}
                show={currentSlide === i}
                animationConfig={BigImageAnimationConfig}
                active={currentSlide === i}
              >
                <BigImage
                  key={i}
                  src={s?.assets?.largeImage?.src}
                  alt={s?.assets?.largeImage?.alt}
                />
              </StyledAnimatedCarouselSlide>
            ))}
          />
        </BigImageWrapper>
        <InfoWrapper>
          <AnimatedDish
            id={LEFT_CAROUSEL_ID}
            aria-live="off"
            elements={slides?.map((s, i) => (
              <AnimatedCarouselSlide
                key={i}
                show={currentSlide === i}
                animationConfig={DishAnimationConfig}
              >
                <DishWrapper key={i}>
                  <DishImage
                    src={s?.assets?.smallImage?.src}
                    alt={s?.assets?.smallImage?.alt}
                  />
                  <Description >
                    {s?.labels?.description}
                  </Description>
                </DishWrapper>
              </AnimatedCarouselSlide>
            ))}
          />
          <ButtonsWrapper>
            {slides[currentSlide]?.labels?.primaryButtonLink && (
              <SlideAction
                type={ButtonType.PRIMARY_LIGHT}
                label={
                  slides[currentSlide]?.labels?.primaryButtonOverrideLabel ||
                  slides[currentSlide]?.labels?.primaryButtonDefaultLabel
                }
                link={slides[currentSlide]?.labels?.primaryButtonLink}
              />
            )}
            {slides[currentSlide]?.labels?.secondaryButtonLink && (
              <SlideAction
                type={ButtonType.SECONDARY_LIGHT}
                label={
                  slides[currentSlide]?.labels?.secondaryButtonOverrideLabel ||
                  slides[currentSlide]?.labels?.secondaryButtonDefaultLabel
                }
                link={slides[currentSlide]?.labels?.secondaryButtonLink}
              />
            )}
          </ButtonsWrapper>
          <ActionsWrapper>
            <Playhead
              aria-controls={`${LEFT_CAROUSEL_ID} ${RIGHT_CAROUSEL_ID}`}
              ref={playButtonRef}
              borderColor="transparent"
              bgColor="transparent"
              progressColor="#75c154"
              animated
              paused={paused}
              onClick={() => setPaused((p) => !p)}
              onAnimationEnd={() =>
                setCurrentSlide((p) => {
                  if (p === slides.length - 1) return 0;
                  return p + 1;
                })
              }
            />
            <CarouselDots
              selectedIndex={currentSlide}
              onChange={handleSlideChange}
              dotsAmount={slides.length}
            />
          </ActionsWrapper>
        </InfoWrapper>
      </MainContent>
      <ImagesPreloader
        images={flatMap(
          slides.map((slide) => [
            slide.assets.smallImage.src,
            slide.assets.largeImage.src,
          ])
        )}
      />
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  background-color: #154123;
  color: #ffffff;
  padding: 0px 0px ${toVWMobile(140)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 ${toVWDesktop(130)}vw;
  }

  .marquee-container {
    z-index: 3;
    order: -1;
    overflow: hidden;
  }
`;

const MarqueeWrapper = styled.div`
  background-color: #012102;
  padding-bottom: ${toVWMobile(95)}vw;
  direction: ltr;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 ${toVWDesktop(25)}vw;
  }
`;

const HeaderText = styled.h1`
  overflow: hidden;
  white-space: nowrap;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(60)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.06em;
  line-height: ${toVWMobile(72)}vw;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-right: ${toVWMobile(16)}vw;

  strong {
    color: #75c154;
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(124)}rem;
    line-height: ${toVWDesktop(158)}vw;
    padding-top: ${toVWDesktop(10)}vw;
  }
`;

const MainContent = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const BigImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -${toVWMobile(117)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: -${toVWDesktop(165)}vw;
    margin-right: ${toVWDesktop(100)}vw;
    margin-left: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(100)}vw` : 0};
  }
`;

const AnimatedBigImage = styled(AnimatedCarousel)`
  width: ${toVWMobile(347)}vw;
  height: ${toVWMobile(347)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(716)}vw;
    width: ${toVWDesktop(610)}vw;
  }
`;

const BigImage = styled.img`
  object-fit: cover;
  width: ${toVWMobile(347)}vw;
  height: ${toVWMobile(347)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(716)}vw;
    width: ${toVWDesktop(610)}vw;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -${toVWMobile(30)}vw;
  padding: 0px ${toVWMobile(24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: -${toVWDesktop(85)}vw;
    width: ${toVWDesktop(410)}vw;
    padding: 0;
    margin-right: ${toVWDesktop(134)}vw;
    align-items: flex-start;
  }
`;

const AnimatedDish = styled(AnimatedCarousel)`
  width: 100%;
  height: ${toVWMobile(375)}vw;
  z-index: 3;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(510)}vw;
  }
`;

const DishWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DishImage = styled.img`
  align-self: center;
  object-fit: contain;
  width: ${toVWMobile(297)}vw;
  height: ${toVWMobile(249)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(410)}vw;
    height: ${toVWDesktop(343)}vw;
    z-index: 1;
  }
`;

const Description = styled.p`
  text-transform: uppercase;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.06em;
  line-height: normal;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  margin-bottom: ${toVWMobile(38)}vw;
  max-width: ${toVWMobile(285)}vw;
  align-self: flex-start;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: ${toVWDesktop(403)}vw;
    font-size: ${toREM(36)}rem;
    line-height: ${toVWDesktop(40)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
    margin-left: ${toVWDesktop(35)}vw;
    padding-top: ${toVWDesktop(10)}vw;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-bottom: ${toVWDesktop(50)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    margin: ${toVWDesktop(15)}vw 0 ${toVWDesktop(34)}vw ${toVWDesktop(35)}vw;
  }
`;

const SlideAction = styled(ButtonLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${toVWMobile(15)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-height: ${toVWDesktop(57)}vw;
    margin-bottom: unset;
    width: ${toVWDesktop(180)}vw;
    height: ${toVWDesktop(57)}vw;
    margin-right: ${toVWDesktop(20)}vw;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${vwMobile(23)};
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(23)};
    margin-left: ${toVWDesktop(35)}vw;
  }
`;

const StyledAnimatedCarouselSlide = styled(AnimatedCarouselSlide)<{
  active: boolean;
}>`
  z-index: ${(props) => (props.active ? 2 : 0)};
`;

export default HomeMainCarousel;
