import React, { useEffect, useMemo, useState } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import AnimatedCarousel from "../shared/AnimatedCarousel";
import AnimatedCarouselSlide from "../shared/AnimatedCarouselSlide";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import PlayButton from "../shared/PlayArrowButton/PlayArrowButton";
import LocatorIcon from "../../assets/images/location_icon.svg";
import Input from "../shared/Input";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export type LocatorCarouselSlide = {
  brandImage: Image;
  brandName: string;
  heading: string;
  largeImage: Image;
  leftButtonLabel: string;
  leftButtonLink: string;
  rightButtonLabel: string;
  rightButtonLink: string;
};

export type LocatorCarouselProps = {
  title: string;
  eyebrow: string;
  description: string;
  inputPlaceholder: string;
  inputActionLabel: string;
  inputActionLink: string;
  slides: LocatorCarouselSlide[];
};

const LOCATOR_CAROUSEL_ID = "LOCATOR_CAROUSEL";

export default function LocatorCarousel(
  props: LocatorCarouselProps
): JSX.Element | null {
  const labels = useStaticLabels();
  const { slides } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [q, setQ] = useState("");

  if (!slides?.length) return null;

  const [currentSlide, setCurrentSlide] = useState<LocatorCarouselSlide>(
    slides[activeSlide]
  );

  const mainImageAnimation = useMemo(() => {
    return {
      from: { transform: `translateX(100%)` },
      enter: { transform: `translateX(0)` },
      leave: { delay: 1000 },
    };
  }, []);

  const mainImageAnimationReverse = useMemo(() => {
    return {
      from: { transform: `translateX(-100%)` },
      enter: { transform: `translateX(0)` },
      leave: { delay: 1000 },
    };
  }, []);

  const handleNext = () => {
    setReverse(false);
    setActiveSlide((prev) => {
      return prev === slides?.length - 1 ? 0 : prev + 1;
    });
  };

  const handlePrev = () => {
    setReverse(true);
    setActiveSlide((prev) => {
      return prev === 0 ? slides?.length - 1 : prev - 1;
    });
  };

  useEffect(() => {
    setCurrentSlide(slides[activeSlide]);
  }, [activeSlide, slides]);

  return (
    <Container>
      <Gallery>
        <MainImageCarousel
          id={LOCATOR_CAROUSEL_ID}
          aria-live="polite"
          elements={slides.map((slide, i) => (
            <AnimatedSlide
              key={`slideImg-${i}`}
              show={activeSlide === i}
              animationConfig={
                reverse ? mainImageAnimationReverse : mainImageAnimation
              }
              active={activeSlide === i}
            >
              <img src={slide.largeImage.src} alt={slide.largeImage.alt} />
            </AnimatedSlide>
          ))}
        />
        <BrandCard>
          <BrandCardContent>
            <img
              src={currentSlide?.brandImage?.src}
              alt={currentSlide?.brandImage?.alt}
            />
            <h4 className="title">{currentSlide?.brandName}</h4>
          </BrandCardContent>
          <BrandCardActions>
            <BrandCardActionButton
              link={currentSlide?.leftButtonLink}
              label={currentSlide?.leftButtonLabel}
              type={ButtonType.SECONDARY_LIGHT}
            />
            <BrandCardActionButton
              link={currentSlide?.rightButtonLink}
              iconComponent={
                <InlineSVG
                  className="icon"
                  src={LocatorIcon}
                  title={labels?.locator}
                />
              }
              label={currentSlide?.rightButtonLabel}
              type={ButtonType.SECONDARY_LIGHT}
            />
          </BrandCardActions>
        </BrandCard>
        {slides?.length > 1 && (
          <ArrowButtons>
            <PlayButton
              aria-controls={`${LOCATOR_CAROUSEL_ID}`}
              borderColor="#033305"
              bgColor=" #75c154"
              iconColor="#030504"
              onClick={handleNext}
            />
            <PlayButton
              aria-controls={`${LOCATOR_CAROUSEL_ID}`}
              borderColor="#033305"
              bgColor=" #75c154"
              iconColor="#030504"
              rotate
              onClick={handlePrev}
            />
          </ArrowButtons>
        )}
      </Gallery>
      <RightSide>
        <>
          <Subtitle>{props?.eyebrow}</Subtitle>
          <Title>{props?.title}</Title>
          <Description>{props?.description}</Description>
        </>

        <Form>
          <Input
            value={q}
            onChange={(e) => setQ(e.target.value)}
            label={props?.inputPlaceholder}
          />
          <ButtonLink
            link={props?.inputActionLink + `?zip=${q}`}
            type={ButtonType.PRIMARY_LIGHT}
            label={props?.inputActionLabel}
          />
        </Form>
      </RightSide>
    </Container>
  );
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(20)};
  }

  a {
    display: inline-block;
    min-width: fit-content;

    width: 100%;
    height: ${vwMobile(56)};

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(85)};
      height: ${vwDesktop(56)};
    }
  }
`;

const MainImageCarousel = styled(AnimatedCarousel)`
  width: 100%;
  height: 100%;
`;

const ArrowButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  direction: ltr;
  position: absolute;
  top: ${vwMobile(220)};
  left: ${vwMobile(13)};
  right: ${vwMobile(13)};
  z-index: 3;

  ${MEDIA_DESKTOP} {
    direction: inherit;
    flex-direction: column;
    top: ${vwDesktop(190)};
    left: 0;
    right: auto;
    gap: ${vwDesktop(20)};
    transform: translateX(-50%);
  }
`;

const RightSide = styled.div`
  width: 100%;
  padding-inline: ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(399)};
    padding-inline: 0;
  }
  .items {
    position: relative;
    width: 100%;
    height: ${vwMobile(300)};

    ${MEDIA_DESKTOP} {
      height: ${vwDesktop(263)};
      margin-bottom: ${vwDesktop(40)};
    }

    .item {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Gallery = styled.div`
  position: relative;
  width: 100%;
  height: ${vwMobile(504)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(568)};
    height: ${vwDesktop(522)};
  }
`;

const BrandCard = styled.div`
  position: absolute;
  bottom: ${vwMobile(-68)};
  left: ${vwMobile(12)};
  right: ${vwMobile(12)};

  z-index: 3;

  ${MEDIA_DESKTOP} {
    bottom: ${vwDesktop(-68)};
    left: ${vwDesktop(30)};
    right: ${vwDesktop(30)};
  }
`;

const BrandCardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: black;
  background-color: #ffffff;
  gap: ${vwMobile(24)};
  height: ${vwMobile(116)};
  padding-inline: ${vwMobile(24)};
  margin-bottom: ${vwMobile(3)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(46)};
    height: ${vwDesktop(116)};
    padding-inline: ${vwDesktop(40)};
    margin-bottom: ${vwDesktop(3)};
  }

  img {
    object-fit: contain;
    height: ${vwMobile(76)};
    width: ${vwMobile(76)};

    ${MEDIA_DESKTOP} {
      height: ${vwDesktop(76)};
      width: ${vwDesktop(76)};
    }
  }

  .title {
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${rem(18)};
    line-height: ${rem(21)};
    font-weight: 900;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${MEDIA_DESKTOP} {
      font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
      font-size: ${rem(24)};
      line-height: ${rem(31)};
      letter-spacing: 0.06em;
    }
  }
`;

const BrandCardActions = styled.div`
  display: flex;
  gap: ${vwMobile(3)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(3)};
  }
`;

const BrandCardActionButton = styled(ButtonLink)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${vwMobile(45)};
  font-size: ${rem(16)};
  font-weight: 700;
  text-decoration: none;
  color: #2b6447;

  svg {
    path {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  ${MEDIA_DESKTOP} {
    color: #012102;
    height: ${vwDesktop(45)};
  }

  .icon {
    display: inline-block;
    margin-right: ${rem(12)};
    width: ${vwMobile(24)};
    height: ${vwMobile(24)};

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(24)};
      height: ${vwDesktop(24)};
    }
  }
`;

const Title = styled.h2`
  color: #ffffff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${rem(48)};
  margin-bottom: ${vwMobile(8)};
  letter-spacing: 0.03em;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(56)};
    line-height: ${rem(64)};
    letter-spacing: 0.03em;
    margin-bottom: ${vwDesktop(3)};
  }
`;

const Subtitle = styled.h3`
  font-weight: 700;
  font-size: ${rem(18)};
  letter-spacing: ${rem(1.44)};
  text-transform: uppercase;
  margin-bottom: ${vwMobile(8.4)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(10)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 600;
  margin-bottom: ${vwMobile(38)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(40)};
  }
`;

const Container = styled.div`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #033305;
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(108)};

  padding-bottom: ${vwMobile(40)};

  color: #ffffff;

  ${MEDIA_DESKTOP} {
    flex-direction: row-reverse;
    align-items: center;
    gap: ${vwDesktop(63)};

    padding-top: ${vwDesktop(80)};
    padding-right: ${vwDesktop(205)};
    padding-bottom: ${vwDesktop(148)};
    padding-left: ${vwDesktop(205)};
  }
`;

const AnimatedSlide = styled(AnimatedCarouselSlide)<{
  active: boolean;
}>`
  z-index: ${(props) => (props.active ? 2 : 0)};

  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
