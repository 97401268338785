import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { getAssetURL } from "../../helpers/assets";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA, MEDIA_DESKTOP } from "../../helpers/theme";
import { RecipeImage } from "./Recipes";
import Link from "../shared/Link";
import { useMediaQuery } from "@react-hook/media-query";
import { useCallback } from "react";
import { useEffect } from "react";

export type ItemProps = {
  width: string;
  height: string;
  image: RecipeImage;
};

const Item = (props: ItemProps): ReactElement => {
  const [taps, setTaps] = useState(0);
  const { image } = props;
  const [linkText, setLinkText] = useState(image.name || image.button_label);
  const isMobile = useMediaQuery(MEDIA.MOBILE);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (isMobile) {
        if (taps < 1) e.preventDefault(), setTaps((t) => t + 1);
        else setTaps(0);
      }
    },
    [taps, isMobile]
  );

  return (
    <StyledItem
      onMouseEnter={() => setLinkText(image.button_label)}
      onMouseLeave={() => setLinkText(image.name)}
      onClick={handleClick}
      to={image.button_url}
      {...props}
    >
      <img src={getAssetURL(image.image)} alt={image.image_alt} />
      <span>{linkText}</span>
    </StyledItem>
  );
};
export default Item;

export const StyledItem = styled(Link)<{
  width: number | string;
  height: number | string;
}>`
  display: grid;
  grid-template: auto / auto;
  > * {
    grid-area: 1/1;
  }

  position: relative;
  width: ${(p) => p.width};
  height: ${(p) => p.height};

  color: black;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }

    span {
      background-color: #75c154;
      padding-inline: ${vwMobile(40)};

      ${MEDIA_DESKTOP} {
        padding-inline: ${vwDesktop(40)};
      }
    }
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    transition: all 0.3s ease-out;
  }

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: ${vwMobile(12)};
    text-transform: capitalize;

    font-size: ${rem(14)};
    font-weight: 700;
    letter-spacing: 0.00014em;
    text-decoration: none;
    color: inherit;

    background-color: white;
    transition: all 0.2s;
    cursor: pointer;

    ${MEDIA_DESKTOP} {
      padding: ${vwDesktop(12)};
    }
  }
`;
