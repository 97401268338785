import React, { ReactElement } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { vwMobile } from "../../helpers/styles";
import Item from "./Item";
import { RecipeImage } from "./Recipes";

type CarouselProps = {
  itemWidth: string;
  itemHeight: string;
  images: RecipeImage[];
};

export default function Carousel({
  itemWidth,
  itemHeight,
  images,
}: CarouselProps): ReactElement {
  return (
    <Slider
      {...{
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      }}
    >
      {images?.map((image, i) => (
        <ItemContainer key={i}>
          <Item width={itemWidth} height={itemHeight} image={image} />
        </ItemContainer>
      ))}
    </Slider>
  );
}

const ItemContainer = styled.div`
  padding-inline: ${vwMobile(10)};
`;
