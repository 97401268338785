import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TranslatableComponent, Image as ImageType } from "../../types";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { ButtonLink } from "../shared/ButtonLink";
import { BREAKPOINTS } from "../../helpers/theme";
import { getAssetURL } from "../../helpers/assets";
import { Link } from "../shared/Link";
import useRightToLeft from "../../hooks/useRightToLeft";
import { shuffle } from "lodash";
import Marquee from "react-fast-marquee";

type PartnersAssets = {
  images: ImageType[];
};

type PartnersLabels = {
  title: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;
  bottomLeft: string;
  bottomRightLabel: string;
  bottomRightLink: string;
};

export type PartnersProps = TranslatableComponent<
  PartnersAssets,
  PartnersLabels
>;

const duplicatePartners = (partnersShuffled = []) => {
  return [...partnersShuffled, ...partnersShuffled];
};

const getTimePerImage = (partners = []): string => {
  let time;
  if (window.screen.width > BREAKPOINTS.DESKTOP) {
    time = (partners.length - 2) * 5 + 2;
  }
  if (window.screen.width < BREAKPOINTS.DESKTOP) {
    time = (partners.length - 2) * 12 + 2;
  }
  return (time || "").toString().concat("s");
};

const getDistanceToMove = (partners = []): number => {
  if (window.screen.width > BREAKPOINTS.DESKTOP) {
    return partners?.length * 9.2;
  }
  return partners?.length * 28.75;
};

const Partners = ({
  assets,
  labels,
  isPublished,
}: PartnersProps): JSX.Element => {
  const [partnersArrayToUse, setPartnersArray] = useState<ImageType[]>([]);
  const [animationTime, setAnimationTime] = useState<string>("");
  const [spaceToMove, setSpaceToMove] = useState<number>(0);
  const isRTL = useRightToLeft();

  if (!isPublished) return null;

  useEffect(() => {
    const arrayShuffled = shuffle(assets?.images);
    const partnersShuffleDuplicate = duplicatePartners(arrayShuffled);
    setPartnersArray(partnersShuffleDuplicate);
    setAnimationTime(getTimePerImage(arrayShuffled));
    setSpaceToMove(getDistanceToMove(partnersShuffleDuplicate));
  }, [assets?.images]);

  return (
    <PartnerSectionContainer>
      <TitleContainer>
        <MainTitle>{labels?.title}</MainTitle>
        <PartnerDescription>{labels?.description}</PartnerDescription>
      </TitleContainer>
      <PartnersContainer>
        <Marquee direction={isRTL ? "right" : "left"} gradient={false}>
          {Object.keys(partnersArrayToUse).map((key: string) => (
            <Partner
              key={partnersArrayToUse[key]?.image_alt + key}
              src={getAssetURL(partnersArrayToUse[key]?.image)}
              alt={partnersArrayToUse[key]?.image_alt}
            />
          ))}
        </Marquee>
      </PartnersContainer>
      <BottomContainer>
        <ButtonLink label={labels?.buttonLabel} link={labels?.buttonLink} />
        <TextContainer>
          <BottomLeftText>{labels?.bottomLeft}</BottomLeftText>
          <BottomRightLink to={labels?.bottomRightLink}>
            {labels?.bottomRightLabel}
          </BottomRightLink>
        </TextContainer>
      </BottomContainer>
    </PartnerSectionContainer>
  );
};

const PartnerSectionContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #58250f;
  position: relative;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: ${toVWMobile(24)}vw;
  padding-left: ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: ${toVWDesktop(298)}vw;
    padding-left: ${toVWDesktop(298)}vw;
  }
`;

const MainTitle = styled.h2`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: ${toREM(40)}rem;
  font-size: ${toREM(36)}rem;
  text-transform: uppercase;
  padding-top: ${toVWMobile(80)}vw;
  justify-content: center;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    line-height: ${toREM(54)}rem;
    padding-top: ${toVWDesktop(80)}vw;
  }
`;

const PartnerDescription = styled.p`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 500;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  padding-top: ${toVWMobile(12)}vw;
  text-align: center;
  justify-content: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    padding-top: ${toVWDesktop(4)}vw;
  }
`;

const PartnersContainer = styled.div`
  direction: ltr;
  padding: ${toVWMobile(53)}vw 0 ${toVWMobile(60)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(68)}vw 0 ${toVWDesktop(82.2)}vw;
  }
`;

const Partner = styled.img`
  width: ${toVWMobile(162)}vw;
  height: ${toVWMobile(162)}vw;
  margin-left: ${toVWMobile(26)}vw;
  object-fit: cover;
  display: inline-block;
  background-color: #ffffff;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(220)}vw;
    height: ${toVWDesktop(220)}vw;
    margin-left: ${toVWDesktop(36)}vw;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${toVWMobile(40.3)}vw;
  padding-right: ${toVWMobile(24)}vw;
  padding-left: ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-bottom: ${toVWDesktop(81.2)}vw;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    padding-top: ${toVWDesktop(27.6)}vw;
  }
`;

const BottomLeftText = styled.p`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: ${toREM(28)}rem;
  font-size: ${toREM(18)}rem;
  padding-top: ${toVWMobile(28.5)}vw;
  justify-content: center;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-weight: 600;
    width: fit-content;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    letter-spacing: 0.04em;
  }
`;

const BottomRightLink = styled(Link)`
  background-color: #58250f;
  width: fit-content;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  color: #75c154;
  font-weight: 700;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  padding: 0;
  display: flex;
  letter-spacing: 0.01em;
  text-decoration: underline;
  border: none;
  align-self: center;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: fit-content;
    width: fit-content;
    font-weight: 900;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    letter-spacing: 0.04em;
    line-height: ${toREM(19)}rem;
    padding-top: 0;
    padding-left: ${toVWDesktop(8)}vw;
    padding-bottom: 0;
  }
`;

export default Partners;
