import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../helpers/theme";
import { toVWMobile, toVWDesktop } from "../../../helpers/styles";

interface Props {
  selectedIndex: number;
  dotsAmount: number;
  onChange: (v: number) => void;
}

export const CarouselDots: React.FC<Props> = ({
  onChange,
  selectedIndex,
  dotsAmount,
}: Props) => {
  return (
    <Wrapper>
      {new Array(dotsAmount).fill("").map((_, ind) => (
        <Dot
          selected={ind === selectedIndex}
          key={ind}
          onClick={(): void => onChange(ind)}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: ${toVWMobile(28)}vw;
  align-items: center;
  justify-content: center;
  gap: ${toVWMobile(8)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-width: ${toVWDesktop(403)}vw;
    height: ${toVWDesktop(29)}vw;
    justify-content: flex-start;
    gap: ${toVWDesktop(8)}vw;
  }
`;

const Dot = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "#ffffff" : "transparent")};
  border: ${({ selected }) =>
    selected ? "0px" : `${toVWMobile(2)}vw solid #75c154`};
  border-radius: ${toVWMobile(13)}vw;
  margin: ${({ selected }) => `${toVWMobile(selected ? 0 : 2)}vw;`};
  width: ${({ selected }) => `${toVWMobile(selected ? 16 : 12)}vw;`};
  height: ${({ selected }) => `${toVWMobile(selected ? 16 : 12)}vw;`};
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    border-radius: ${toVWDesktop(13)}vw;
    border: ${({ selected }) =>
      selected ? "0px" : `${toVWDesktop(2)}vw solid #75c154`};

    margin: ${({ selected }) => `${toVWDesktop(selected ? 0 : 2)}vw;`};
    width: ${({ selected }) => `${toVWDesktop(selected ? 16 : 12)}vw;`};
    height: ${({ selected }) => `${toVWDesktop(selected ? 16 : 12)}vw;`};
  }
`;

export default CarouselDots;
