import * as React from "react";
import styled from "styled-components";

export type AnimatedCarouselProps = {
  className?: string;
  elements: Array<React.ReactNode>;
};

export const AnimatedCarousel = ({
  className,
  elements,
  ...props
}: AnimatedCarouselProps) => {
  if (!elements) {
    return null;
  }
  return (
    <Container aria-roledescription="carousel" className={className} {...props}>
      {elements}
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  position: relative;
  & > * {
    position: absolute;
  }
`;

export default AnimatedCarousel;
