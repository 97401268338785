import { flatMap } from "lodash";
import React, { FC, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwMobile,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image as ImageType, TranslatableComponent } from "../../types";
import AnimatedCarousel from "../shared/AnimatedCarousel";
import { AnimatedCarouselSlide } from "../shared/AnimatedCarouselSlide";
import { ButtonLink, ButtonType } from "../shared/ButtonLink";
import { CarouselDots } from "../shared/Carousel";
import ImagesPreloader from "../shared/ImagesPreloader";
import PlayButton, {
  PlayButtonRef,
} from "../shared/PlayArrowButton/PlayArrowButton";
import Playhead from "../shared/PlayButton/PlayButton";
import PlayArrowButton from "../shared/PlayArrowButton/PlayArrowButton";

type ProductCarouselSlideAssets = {
  smallImage: ImageType;
  largeImage: ImageType;
};

type ProductCarouselSlideLabels = {
  primaryButtonLabel: string;
  primaryButtonLink: string;
  secondaryButtonLabel: string;
  secondaryButtonLink: string;
  smallText: string;
  description: string;
};

type ProductCarouselSlideProps = TranslatableComponent<
  ProductCarouselSlideAssets,
  ProductCarouselSlideLabels
>;

export type ProductCarouselProps = {
  slides: ProductCarouselSlideProps[];
};

const LEFT_CAROUSEL_ID = "PRODUCT_CAROUSEL_LEFT";
const RIGHT_IMAGE_CAROUSEL_ID = "PRODUCT_IMAGE_CAROUSEL_RIGHT";
const RIGHT_TEXT_CAROUSEL_ID = "PRODUCT_TEXT_CAROUSEL_RIGHT";

const ProductCarousel: FC<ProductCarouselProps> = ({
  slides,
}: ProductCarouselProps) => {
  const playButtonRef = useRef<PlayButtonRef>(null);

  const [activeSlide, setActiveSlide] = useState<{
    index: number;
    timestamp: string;
  }>({ index: 0, timestamp: new Date().getTime().toString() });

  const [paused, setPaused] = useState(false);
  const [reverse, setReverse] = useState(false);

  const mainImageAnimationConfig = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: { opacity: 0, delay: 500 },
    };
  }, []);

  const mainImageAnimationConfigReverse = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(-100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: { opacity: 0, delay: 500 },
    };
  }, []);

  const secondaryImageAnimationConfig = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);
  const secondaryImageAnimationConfigReverse = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(-100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);

  const textAnimationConfig = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);
  const textAnimationConfigReverse = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(-100%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);

  const handleNext = () => {
    setReverse(false);
    setActiveSlide((prev) => {
      const newIndex = prev?.index === slides?.length - 1 ? 0 : prev?.index + 1;
      return {
        index: newIndex,
        timestamp: new Date().getTime().toString(),
      };
    });
    playButtonRef.current?.reset();
  };

  const handlePrev = () => {
    setReverse(true);
    setActiveSlide((prev) => {
      const newIndex = prev?.index === 0 ? slides?.length - 1 : prev?.index - 1;
      return {
        index: newIndex,
        timestamp: new Date().getTime().toString(),
      };
    });
    playButtonRef.current?.reset();
  };

  const { index: activeSlideIndex } = activeSlide;

  if (!slides.length) return <></>;
  return (
    <Container>
      <CarouselButtons>
        <PlayArrowButton
          aria-controls={`${LEFT_CAROUSEL_ID} ${RIGHT_IMAGE_CAROUSEL_ID}  ${RIGHT_TEXT_CAROUSEL_ID}`}
          borderColor={"#012102"}
          bgColor={"#75C154"}
          onClick={handleNext}
        />
        <PlayArrowButton
          aria-controls={`${LEFT_CAROUSEL_ID} ${RIGHT_IMAGE_CAROUSEL_ID}  ${RIGHT_TEXT_CAROUSEL_ID}`}
          borderColor={"#012102"}
          bgColor={"#75C154"}
          rotate
          onClick={handlePrev}
        />
      </CarouselButtons>
      <LeftSection>
        <MainImageContainer>
          <MainImageCarousel
            id={LEFT_CAROUSEL_ID}
            aria-live="off"
            elements={slides?.map((s, i) => (
              <StyledAnimatedCarouselSlide
                key={i}
                show={activeSlideIndex === i}
                animationConfig={
                  reverse
                    ? mainImageAnimationConfigReverse
                    : mainImageAnimationConfig
                }
                active={activeSlideIndex === i}
              >
                <MainImageCarouselSlide
                  key={i}
                  src={s?.assets?.largeImage?.src}
                  alt={s?.assets?.largeImage?.alt}
                />
              </StyledAnimatedCarouselSlide>
            ))}
          />
        </MainImageContainer>
      </LeftSection>
      <RightSection>
        <div>
          <SecondaryImageContainer>
            <MainImageCarousel
              id={RIGHT_IMAGE_CAROUSEL_ID}
              aria-live="off"
              elements={slides?.map((s, i) => (
                <StyledAnimatedCarouselSlide
                  key={i}
                  show={activeSlideIndex === i}
                  animationConfig={
                    reverse
                      ? secondaryImageAnimationConfigReverse
                      : secondaryImageAnimationConfig
                  }
                  active={activeSlideIndex === i}
                >
                  <SecondaryImageCarouselSlide
                    key={i}
                    src={s?.assets?.smallImage?.src}
                    alt={s?.assets?.smallImage?.alt}
                  />
                </StyledAnimatedCarouselSlide>
              ))}
            />
          </SecondaryImageContainer>
          <TextCarousel
            id={RIGHT_TEXT_CAROUSEL_ID}
            aria-live="off"
            elements={slides?.map((s, i) => (
              <StyledAnimatedCarouselSlide
                key={i}
                show={activeSlideIndex === i}
                animationConfig={
                  reverse ? textAnimationConfigReverse : textAnimationConfig
                }
                active={activeSlideIndex === i}
              >
                <SubTitle>
                  {slides[activeSlideIndex]?.labels?.smallText}
                </SubTitle>
                <Title>{slides[activeSlideIndex]?.labels?.description}</Title>
              </StyledAnimatedCarouselSlide>
            ))}
          />

          <ButtonsContainer>
            <ButtonLink
              label={slides[activeSlideIndex]?.labels?.primaryButtonLabel}
              link={slides[activeSlideIndex]?.labels?.primaryButtonLink}
            />
            <StyledButtonLink
              type={ButtonType.SECONDARY_LIGHT}
              label={slides[activeSlideIndex]?.labels?.secondaryButtonLabel}
              link={slides[activeSlideIndex]?.labels?.secondaryButtonLink}
            />
          </ButtonsContainer>
        </div>
        <CarouselInfo>
          <CarouselDots
            dotsAmount={slides?.length}
            selectedIndex={activeSlideIndex}
            onChange={(index) => {
              setActiveSlide({
                index,
                timestamp: new Date().getTime().toString(),
              });
              playButtonRef.current?.reset();
            }}
          />

          <Playhead
            aria-controls={`${LEFT_CAROUSEL_ID} ${RIGHT_IMAGE_CAROUSEL_ID}  ${RIGHT_TEXT_CAROUSEL_ID}`}
            ref={playButtonRef}
            duration={7}
            progressColor="#75c154"
            iconColor="white"
            borderColor="transparent"
            bgColor="transparent"
            animated
            paused={paused}
            onClick={() => setPaused((p) => !p)}
            onAnimationEnd={() => {
              setActiveSlide((p) => ({
                index: p.index === slides.length - 1 ? 0 : p.index + 1,
                timestamp: new Date().getTime().toString(),
              }));
            }}
          />
        </CarouselInfo>
      </RightSection>
      <DarkBackground aria-hidden={true} />
      <ImagesPreloader
        images={flatMap(
          slides.map((slide) => [
            slide.assets.smallImage.src,
            slide.assets.largeImage.src,
          ])
        )}
      />
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  width: 100%;
  background-color: #033305;
  display: flex;
  flex-direction: column;
  color: #ffffff;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    width: 100%;
    padding: ${toVWDesktop(49)}vw ${toVWDesktop(100)}vw;
  }
`;

const DarkBackground = styled.div`
  position: absolute;
  bottom: ${toVWMobile(40)}vw;
  width: ${toVWMobile(351)}vw;
  height: ${toVWMobile(560)}vw;
  left: ${toVWMobile(10)}vw;

  background-color: #012102;
  z-index: 1;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    z-index: 0;
    left: auto;
    height: ${toVWDesktop(630)}vw;
    width: ${toVWDesktop(1240)}vw;
    bottom: ${toVWDesktop(49)}vw;
  }
`;

const LeftSection = styled.div`
  z-index: 1;
  padding-left: ${toVWMobile(0)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-left: ${(props) =>
      props.theme.isRTL ? 0 : `${toVWDesktop(105)}vw`};
    padding-bottom: ${toVWDesktop(90)}vw;
    padding-right: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(105)}vw` : 0};
  }
`;

const RightSection = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-left: ${toVWMobile(36)}vw;
  padding-right: ${toVWMobile(36)}vw;
  height: ${toVWMobile(555)}vw;
  top: ${toVWMobile(-125)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: auto;
    z-index: 1;
    top: unset;
    justify-content: space-between;
    padding-left: ${toVWDesktop(75)}vw;
    padding-right: ${toVWDesktop(53)}vw;
    padding-bottom: ${toVWDesktop(90)}vw;
  }
`;

const MainImageContainer = styled.div`
  position: relative;
  width: ${toVWMobile(375)}vw;
  height: ${toVWMobile(375)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(610)}vw;
    height: ${toVWDesktop(655)}vw;
  }
`;

const StyledAnimatedCarouselSlide = styled(AnimatedCarouselSlide)<{
  active: boolean;
}>`
  z-index: ${(props) => (props.active ? 2 : 0)};
`;

const MainImageCarouselSlide = styled.img`
  width: ${toVWMobile(375)}vw;
  height: ${toVWMobile(375)}vw;
  object-fit: cover;
  object-position: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(610)}vw;
    height: ${toVWDesktop(655)}vw;
  }
`;

const CarouselButtons = styled.div`
  position: absolute;
  display: flex;
  width: ${toVWMobile(353)}vw;
  height: ${toVWMobile(375)}vw;
  top: ${toVWMobile(158)}vw;
  direction: ltr;
  left: ${vwMobile(12)};
  flex-direction: row-reverse;
  justify-content: space-between;
  z-index: 2;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    direction: inherit;
    flex-direction: column;
    gap: ${toVWDesktop(20)}vw;
    top: ${toVWDesktop(357)}vw;
    left: ${(props) =>
      props.theme.isRTL ? `${vwDesktop(600)}` : `${vwDesktop(792)}`};
    height: ${toVWDesktop(111)}vw;
    width: ${toVWDesktop(46)}vw;
  }
`;

const MainImageCarousel = styled(AnimatedCarousel)`
  width: 100%;
  height: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 100%;
    height: 100%;
  }
`;

const TextCarousel = styled(MainImageCarousel)`
  max-height: ${toVWMobile(190)}vw;
  height: ${toVWMobile(190)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(223)}vw;
    max-height: ${toVWDesktop(223)}vw;
    margin-top: ${toVWDesktop(15)}vw;
  }
`;

const SecondaryImageContainer = styled.div`
  width: 100%;
  height: ${toVWMobile(169.63)}vw;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(329)}vw;
    height: ${toVWDesktop(251)}vw;
  }
`;

const SecondaryImageCarouselSlide = styled.img`
  width: ${toVWMobile(224.36)}vw;
  height: ${toVWMobile(169.63)}vw;
  object-fit: contain;
  object-position: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(329)}vw;
    height: ${toVWDesktop(251)}vw;
  }
`;

const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(28)}rem;
  line-height: ${toREM(40)}rem;
  letter-spacing: 0.03em;
  font-weight: 900;
  margin-top: ${toVWMobile(12)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(12)}vw;
    font-size: ${toREM(36)}rem;
    line-height: ${toREM(49)}rem;
    letter-spacing: 0.03em;
    font-weight: 900;
  }
`;

const SubTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  letter-spacing: 0.08em;
  font-weight: 800;
  margin-top: ${toVWMobile(8)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${toVWMobile(16)}vw;
  margin-top: ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    gap: ${toVWDesktop(20)}vw;
    margin-top: ${toVWDesktop(40)}vw;
  }
`;

const CarouselInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${toVWMobile(18)}vw;
  justify-content: center;
  margin-top: ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${toVWDesktop(18)}vw;
    justify-content: flex-start;
    margin-top: ${toVWDesktop(20)}vw;
    align-items: center;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-height: ${toVWDesktop(57)}vw;
  }
`;

export default ProductCarousel;
