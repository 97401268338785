import * as React from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";
import { TranslatableComponent, Image as ImageType } from "../../types";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { getLimits } from "../../helpers/parallax";
import { ButtonLink } from "../shared/ButtonLink";
import { BREAKPOINTS } from "../../helpers/theme";

type IngredientsAssets = {
  image1: ImageType;
  image2: ImageType;
  image3: ImageType;
};

type IngredientsLabels = {
  mainTitle: string;
  title: string;
  subtitle: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;
};

export type IngredientsProps = TranslatableComponent<
  IngredientsAssets,
  IngredientsLabels
>;

const Ingredients = ({ assets, labels }: IngredientsProps) => {
  return (
    <>
      <Container>
        <ImagesContainer>
          <ParallaxImage1 y={getLimits(["-50px", "25px"], ["-180px", "90px"])}>
            <img src={assets?.image1?.src} alt={assets?.image1?.alt} />
          </ParallaxImage1>
          <ParallaxImage2 y={getLimits(["-60px", "30px"], ["-140px", "70px"])}>
            <img src={assets?.image2?.src} alt={assets?.image2?.alt} />
          </ParallaxImage2>
          <ParallaxImage3 y={getLimits(["-20px", "10px"], ["-40px", "20px"])}>
            <img src={assets?.image3?.src} alt={assets?.image3?.alt} />
          </ParallaxImage3>
          <ParallaxTitle
            x={getLimits(["50%", "-100%"], ["0", "0"])}
            y={getLimits(["0px", "0px"], ["-40px", "0px"])}
          >
            <Title>{labels.mainTitle}</Title>
          </ParallaxTitle>
        </ImagesContainer>

        <TextContainerParallax
          y={getLimits(["-10px", "5px"], ["-180px", "45px"])}
        >
          <AlternateText>{labels.subtitle}</AlternateText>
          <MainText dangerouslySetInnerHTML={{ __html: labels.title }} />
          <Description>{labels.description}</Description>
          <ButtonLink label={labels.buttonLabel} link={labels.buttonLink} />
        </TextContainerParallax>
      </Container>
    </>
  );
};

const Container = styled.section`
  width: 100%;
  background-color: #304f4c;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw ${toVWMobile(44)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row-reverse;
    padding: ${toVWDesktop(82)}vw ${toVWDesktop(135)}vw ${toVWDesktop(108)}vw
      ${toVWDesktop(193)}vw;
  }
`;

const TextContainerParallax = styled(Parallax)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 29.35%;
    padding-bottom: ${toVWDesktop(45)}vw;
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  height: ${toVWMobile(644)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 70.64%;
    height: ${toVWDesktop(644)}vw;
  }
`;

const ParallaxImage1 = styled(Parallax)`
  width: ${toVWMobile(189)}vw;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(189)}vw;
    left: ${toVWDesktop(51)}vw;
    top: ${toVWDesktop(407)}vw;
    right: auto;
  }
  & img {
    width: 100%;
  }
`;

const ParallaxImage2 = styled(Parallax)`
  width: ${toVWMobile(281)}vw;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: ${toVWMobile(279)}vw;
  left: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(401)}vw;
    right: ${toVWDesktop(69)}vw;
    top: ${toVWDesktop(174)}vw;
    left: auto;
  }
  & img {
    width: 100%;
  }
`;

const ParallaxImage3 = styled(Parallax)`
  width: ${toVWMobile(168)}vw;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: ${toVWMobile(476)}vw;
  right: 0;
  z-index: 1;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(100)}vw;
    width: ${toVWDesktop(168)}vw;
    right: ${toVWDesktop(382)}vw;
  }
  & img {
    width: 100%;
  }
`;

const Title = styled.h2``;

const ParallaxTitle = styled(Parallax)`
  position: absolute;
  top: ${toVWMobile(215)}vw;
  & ${Title} {
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-weight: 900;
    font-size: ${toREM(88)}rem;
    line-height: ${toREM(96)}rem;
    letter-spacing: 0.04em;
    color: #678e8b;
    text-transform: uppercase;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(20)}vw;

    & ${Title} {
      font-size: ${toREM(120)}rem;
      line-height: ${toREM(74)}rem;
    }
  }
`;

const AlternateText = styled.p`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  font-size: ${toREM(18)}rem;
  letter-spacing: 0.08em;
  line-height: ${toREM(24)}rem;
  color: #ffffff;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(18)}rem;
    letter-spacing: 0.08em;
    line-height: ${toREM(24)}rem;
  }
`;

const MainText = styled.h3`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(48)}rem;
  letter-spacing: 0.03em;
  line-height: ${toREM(56)}rem;
  margin-top: ${toVWMobile(8)}vw;
  color: #ffffff;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(64)}rem;
    margin-top: ${toVWDesktop(9)}vw;
  }
`;

const Description = styled.p`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  font-size: ${toREM(18)}rem;
  letter-spacing: 0;
  line-height: ${toREM(28)}rem;
  color: #ffffff;
  margin-bottom: ${toVWMobile(40)}vw;
  margin-top: ${toVWMobile(9)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(8)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
  }
`;

export default Ingredients;
