import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";
import {
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import triangle from "./assets/triangle.svg";
import pause from "./assets/pause.svg";
import { useStaticLabels } from "../../../hooks/useStaticLabels";

interface Props {
  animated?: boolean;
  disabled?: boolean;
  onAnimationEnd?: () => void;
  onClick?: () => void;
  iconColor?: string;
  duration?: number;
  progressColor?: string;
  ref?: React.MutableRefObject<{
    startInterval: () => void;
    reset: () => void;
  }>;
  paused?: boolean;
}

export type PlayButtonRef = {
  reset: () => void;
};

// eslint-disable-next-line react/display-name
export const PlayButton = React.forwardRef((props: Props, ref) => {
  const {
    disabled = false,
    duration = 7,
    iconColor = "white",
    progressColor,
    onAnimationEnd,
    onClick,
    animated = false,
    paused = false,
    ...restProps
  } = props;

  const [playing, setPlaying] = useState(animated);

  const outerCircle = useRef<SVGPathElement>(null);

  const labels = useStaticLabels();

  const reset = useCallback(() => {
    const el = outerCircle.current;
    if (el) {
      // circle is the progress with the animation
      el.classList.remove("circle");
      setTimeout(() => {
        el.classList.add("circle");
      }, 0);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      reset,
    }),
    []
  );

  const handleClick = useCallback((): void => {
    reset();
    onClick?.();
  }, [onClick, onAnimationEnd]);

  const handleAnimationEnd = useCallback(() => {
    onAnimationEnd?.();
    reset();
  }, []);

  useEffect(() => {
    if (!playing && animated) setPlaying(true);
  }, [playing, animated]);

  return (
    <Button
      {...restProps}
      disabled={disabled}
      duration={duration}
      stroke={progressColor}
      iconFill={iconColor}
      onClick={handleClick}
      aria-label={paused ? labels?.pause : labels?.play}
    >
      {animated && (
        <PlayButtonOuterCircle
          className={`${playing ? "playing" : ""}`}
          role="img"
          aria-hidden={true}
          viewBox="0 0 36 36"
        >
          <circle
            ref={outerCircle}
            className={`circle ${paused ? "paused" : ""}`}
            cx="50%"
            cy="50%"
            r="46.5%"
            strokeDasharray="0, 295.159265359%"
            stroke="#ffffff"
            strokeWidth="7%"
            fill="transparent"
            onAnimationEnd={handleAnimationEnd}
          />
        </PlayButtonOuterCircle>
      )}
      {paused ? (
        <Pause src={pause} alt={labels?.pause} />
      ) : (
        <Triangle src={triangle} alt={labels?.play} />
      )}
    </Button>
  );
});

const Triangle = styled.img`
  width: ${vwMobile(12)};
  height: ${vwMobile(12)};
  object-fit: contain;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(12)};
    height: ${vwDesktop(12)};
  }
`;
const Pause = styled(Triangle)``;

const show100 = keyframes`
  from {
    stroke-dasharray: 0, 295.159265359%;
  }
  to {
    stroke-dasharray: 295.159265359%, 295.159265359%;
  }
`;
const Button = styled.button<{
  duration: number;
  stroke?: string;
  iconFill?: string;
}>`
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  width: ${vwMobile(37)};
  height: ${vwMobile(37)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(37)}vw;
    height: ${toVWDesktop(37)}vw;
  }

  & .playing > .circle {
    stroke: ${(p) => p.stroke ?? "#033305"};
    animation: ${show100} ${({ duration }) => duration}s;
    animation-timing-function: linear;
  }
`;

const PlayButtonOuterCircle = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  height: ${toVWMobile(37)}vw;
  width: ${toVWMobile(37)}vw;
  fill: transparent;
  transform: rotate(-90deg);

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(37)};
    width: ${vwDesktop(37)};
  }

  .circle {
    fill: none;
    stroke-width: ${toVWMobile(4)}vw;
    &.paused {
      animation-play-state: paused;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      stroke-width: ${toVWDesktop(4)}vw;
    }
  }

  &.paused {
    .circle {
      animation-play-state: paused;
    }
  }
`;

export default PlayButton;
