import * as React from "react";
import styled from "styled-components";
import { animated, AnimatedProps } from "react-spring";
import { useTransition } from "@react-spring/web";
import { useEnv } from "../../../hooks";
import { ENV } from "../../../hooks/useEnv";

export type AnimatedCarouselSlideProps = {
  show: boolean;
  children?: React.ReactNode;
  animationConfig: AnimatedProps;
  className?: string;
};

export const AnimatedCarouselSlide = ({
  show,
  children,
  animationConfig,
  className,
  ...props
}: AnimatedCarouselSlideProps) => {
  const [env] = useEnv();
  const transitions = useTransition(show, animationConfig);

  if (env === ENV.SERVER && show) {
    return (
      <Container aria-roledescription="slide" className={className} {...props}>
        {children}
      </Container>
    );
  }

  return transitions(
    (styles, item) =>
      item && (
        <Container
          aria-roledescription="slide"
          className={className}
          style={styles}
          {...props}
        >
          {children}
        </Container>
      )
  );
};

const Container = styled(animated.div)``;

export default AnimatedCarouselSlide;
