import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { default as InlineSVG } from "react-inlinesvg";
import styled, { keyframes } from "styled-components";
import CircleSVG from "../../../assets/images/circle.svg";
import {
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import rightArrow from "./assets/rightArrow.svg";
import { useStaticLabels } from "../../../hooks/useStaticLabels";

interface Props {
  animated?: boolean;
  disabled?: boolean;
  onAnimationEnd?: () => void;
  onClick?: () => void;
  duration?: number;
  iconColor?: string;
  bgColor?: string;
  borderColor?: string;
  progressColor?: string;
  animation?: boolean;
  ref?: React.MutableRefObject<{
    startInterval: () => void;
    reset: () => void;
  }>;
  rotate?: boolean;
  paused?: boolean;
}

export type PlayButtonRef = {
  reset: () => void;
};

// eslint-disable-next-line react/display-name
export const PlayArrowButton = React.forwardRef((props: Props, ref) => {
  const {
    disabled = false,
    duration = 7,
    borderColor = "#034920",
    iconColor,
    bgColor,
    progressColor,
    onAnimationEnd,
    onClick,
    animated = false,
    rotate,
    paused = false,
    ...restProps
  } = props;

  const [playing, setPlaying] = useState(animated);

  const outerCircle = useRef<SVGPathElement>(null);

  const labels = useStaticLabels();

  const reset = useCallback(() => {
    const el = outerCircle.current;
    if (el) {
      // circle is the progress with the animation
      el.classList.remove("circle");
      setTimeout(() => {
        el.classList.add("circle");
      }, 0);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      reset,
    }),
    []
  );

  const handleClick = useCallback((): void => {
    reset();
    onClick?.();
  }, [onClick, onAnimationEnd]);

  const handleAnimationEnd = useCallback(() => {
    onAnimationEnd?.();
    reset();
  }, []);

  useEffect(() => {
    if (!playing && animated) setPlaying(true);
  }, [playing, animated]);

  return (
    <Button
      {...restProps}
      disabled={disabled}
      duration={duration}
      stroke={progressColor}
      iconFill={iconColor}
      onClick={handleClick}
      aria-label={rotate ? labels?.previous : labels?.next}
    >
      {animated && (
        <PlayButtonOuterCircle
          className={`${playing ? "playing" : ""}`}
          role="img"
          aria-hidden={true}
          viewBox="0 0 36 36"
        >
          <circle
            ref={outerCircle}
            className={`circle ${paused ? "paused" : ""}`}
            cx="50%"
            cy="50%"
            r="46.5%"
            strokeDasharray="0, 314.159265359%"
            stroke="#ffffff"
            strokeWidth="7%"
            fill="transparent"
            onAnimationEnd={handleAnimationEnd}
          />
        </PlayButtonOuterCircle>
      )}

      <Background src={CircleSVG} stroke={borderColor} fill={bgColor} />
      <i className="icon">
        <Arrow
          src={rightArrow}
          title={rotate ? labels?.previous : labels?.next}
          style={{ transform: rotate ? "rotate(180deg)" : "" }}
        />
      </i>
    </Button>
  );
});

const Arrow = styled(InlineSVG)`
  width: ${vwMobile(21)};
  height: ${vwMobile(11)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(21)};
    height: ${vwDesktop(11)};
  }
`;

const show100 = keyframes`
  from {
    stroke-dasharray: 0, 314.159265359%;
  }
  to {
    stroke-dasharray: 314.159265359%, 314.159265359%;
  }
`;

const Button = styled.button<{
  duration: number;
  stroke?: string;
  iconFill?: string;
}>`
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${toVWMobile(45)}vw;
  height: ${toVWMobile(45)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(45)}vw;
    height: ${toVWDesktop(45)}vw;
  }

  & .playing > .circle {
    stroke: ${(p) => p.stroke ?? "#033305"};
    animation: ${show100} ${({ duration }) => duration}s;
    animation-timing-function: linear;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    }
  }

  .icon {
    transition: all 0.2s;
    line-height: 0;
    z-index: 1;
  }
  &:hover {
    .icon {
      transform: scale(1.2);
    }
  }

  svg {
    g {
      path {
        fill: ${(p) => p.iconFill ?? "auto"};
      }
    }
  }
`;

const Background = styled(InlineSVG)<{ stroke?: string; fill?: string }>`
  position: absolute;
  width: ${toVWMobile(45)}vw;
  height: ${toVWMobile(45)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(45)}vw;
    height: ${toVWDesktop(45)}vw;
  }

  & circle {
    stroke: ${(props) => props.stroke};
    fill: ${(props) => props.fill};
  }
`;

const PlayButtonOuterCircle = styled.svg`
  position: absolute;
  top: ${toVWMobile(-3)}vw;
  left: ${toVWMobile(-3)}vw;
  width: ${toVWMobile(51)}vw;
  height: ${toVWMobile(51)}vw;
  transform: rotate(-90deg);

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(-3)}vw;
    left: ${toVWDesktop(-3)}vw;
    width: ${toVWDesktop(51)}vw;
    height: ${toVWDesktop(51)}vw;
  }

  fill: ${(p) => p.fill ?? "#033305"};

  .circle {
    fill: none;
    &.paused {
      animation-play-state: paused;
    }
  }

  &.paused {
    .circle {
      animation-play-state: paused;
    }
  }

  &.no-animation {
    .circle {
      stroke: none;
      animation: none;
    }
  }
`;

export default PlayArrowButton;
