import React from "react";
import styled from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { TranslatableComponent, Image as ImageType } from "../../types";
import { Parallax } from "react-scroll-parallax";
import { getLimits } from "../../helpers/parallax";
import { BREAKPOINTS } from "../../helpers/theme";
import { isPublished } from "../../helpers/directus";
import ButtonLink from "../shared/ButtonLink";

type OurMissionAssets = {
  topPositionImage: ImageType;
  middlePositionImage: ImageType;
  bottomPositionImage: ImageType;
};

type OurMissionLabels = {
  mainTitle1: string;
  mainTitle2: string;
  title: string;
  subtitle: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;
};

export type OurMissionProps = TranslatableComponent<
  OurMissionAssets,
  OurMissionLabels
>;

const OurMission = ({ assets, labels, status }: OurMissionProps) => {
  if (!isPublished(status)) return null;

  return (
    <MissionContainer>
      <TopBackground />
      <TitleContainer>
        <ImageBackground />
        <ImagesContainer>
          <SmallImage1Parallax
            y={getLimits(["-100px", "50px"], ["-40px", "20px"])}
          >
            <img
              src={assets?.topPositionImage?.src}
              alt={assets?.topPositionImage?.alt}
            />
          </SmallImage1Parallax>
          <SmallImage2Parallax
            y={getLimits(["-100px", "50px"], ["-150px", "-70px"])}
          >
            <img
              src={assets?.middlePositionImage?.src}
              alt={assets?.middlePositionImage?.alt}
            />
          </SmallImage2Parallax>
        </ImagesContainer>
        <PrimaryTextContainerWrapper>
          <PrimaryTextContainer>
            <PrimaryText style={{ zIndex: 1 }}>
              {labels?.mainTitle1}
            </PrimaryText>
            <PrimaryText style={{ zIndex: 3 }}>
              {labels?.mainTitle2}
            </PrimaryText>
          </PrimaryTextContainer>
        </PrimaryTextContainerWrapper>
        <LargeImageContainer>
          <BottomImageParallax
            y={getLimits(["-60px", "40px"], ["-40px", "25px"])}
          >
            <img
              src={assets?.bottomPositionImage?.src}
              alt={assets?.bottomPositionImage?.alt}
            />
          </BottomImageParallax>
        </LargeImageContainer>
      </TitleContainer>
      <OurMissionParallax y={getLimits(["-10px", "5px"], ["-180px", "45px"])}>
        <OurMissionAlternateText>{labels?.title}</OurMissionAlternateText>
        <OurMissionPrimaryText>{labels?.subtitle}</OurMissionPrimaryText>
        <OurMissionBody>{labels?.description}</OurMissionBody>
        <ButtonLink
          label={labels?.buttonLabel}
          link={labels?.buttonLink}
          anchor
          blank={false}
        />
      </OurMissionParallax>
    </MissionContainer>
  );
};

const MissionContainer = styled.section`
  width: 100%;
  background-color: #033305;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row-reverse;
  }
`;

const TopBackground = styled.div`
  width: 100%;
  height: ${toVWMobile(47.8)}vw;
  background-color: #154123;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(116)}vw;
    position: absolute;
  }
`;

const ImageBackground = styled.div`
  width: 100%;
  height: ${toVWMobile(222)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 100%;
    height: ${toVWDesktop(434)}vw;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 56.31%;
    display: flex;
    flex-direction: column;
    padding-right: ${toVWDesktop(118)}vw;
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  height: ${toVWMobile(269.7)}vw;
  padding: 0 ${toVWMobile(14.6)}vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 56.31%;
    height: ${toVWDesktop(434)}vw;
    padding: 0;
  }
`;

const SmallImage1Parallax = styled(Parallax)`
  width: 52.34%;
  height: auto;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 32.18%;
    margin-bottom: ${toVWDesktop(42)}vw;
  }
  & img {
    width: 100%;
    object-fit: contain;
  }
`;

const SmallImage2Parallax = styled(Parallax)`
  padding-top: ${toVWMobile(40)}vw;
  width: 42.79%;
  height: auto;
  object-fit: contain;
  z-index: 2;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 21.08%;
    padding-top: 0;
    position: relative;
    align-self: flex-end;
    right: ${toVWDesktop(350)}vw;
    top: -3%;
  }
  & img {
    width: 100%;
    object-fit: contain;
  }
`;

const PrimaryTextContainerWrapper = styled.div`
  position: relative;
  height: ${toVWMobile(95)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(55)}vw;
  }
`;

const PrimaryTextContainer = styled.div`
  position: absolute;
  padding-right: ${toVWMobile(12)}vw;
  top: -${toVWMobile(59)}vw;
  left: -${toVWMobile(15)}vw;
  width: calc(100% + ${toVWMobile(15)}vw);
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: 0;
    top: -${toVWDesktop(59)}vw;
    left: -${toVWDesktop(15)}vw;
    width: calc(100% + ${toVWDesktop(15)}vw);
  }
`;

const PrimaryText = styled.h2`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(91)}rem;
  line-height: ${toREM(83)}rem;
  letter-spacing: 0.04em;
  text-align: ${(props) => (props.theme.isRTL ? "center" : "right")};
  margin: 0;
  color: #485923;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    position: relative;
    top: -${toVWDesktop(70)}vw;
    font-size: ${toREM(120)}rem;
    line-height: ${toREM(100)}rem;
    z-index: 2;
  }
`;

const LargeImageContainer = styled.div`
  width: 100%;
  padding: 0 ${toVWMobile(14.6)}vw;
  display: flex;
  z-index: 1;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: 6%;
    padding-left: 16%;
    z-index: 3;
  }
`;

const BottomImageParallax = styled(Parallax)`
  width: 100%;
  height: auto;
  object-fit: contain;
  & img {
    width: 100%;
    object-fit: contain;
  }
`;

const OurMissionParallax = styled(Parallax)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 43.68%;
    justify-content: center;
    padding-top: ${toVWDesktop(290)}vw;
    padding-left: ${toVWDesktop(205)}vw;
    padding-right: ${toVWDesktop(129)}vw;
  }
`;

const OurMissionAlternateText = styled.p`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: ${toVWMobile(8)}vw;
  font-size: ${toREM(18)}rem;
  letter-spacing: 0.08em;
  line-height: ${toREM(24)}rem;
  color: #ffffff;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(12)}vw;
  }
`;

const OurMissionPrimaryText = styled.h3`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(48)}rem;
  letter-spacing: 0.03em;
  line-height: ${toREM(56)}rem;
  text-transform: uppercase;
  margin-bottom: ${toVWMobile(40)}vw;
  color: #ffffff;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toREM(64)}rem;
    margin-bottom: ${toVWDesktop(4)}vw;
  }
`;

const OurMissionBody = styled.p`
  display: none;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  margin-bottom: ${toVWMobile(63)}vw;
  color: #ffffff;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    margin-bottom: ${toVWDesktop(40)}vw;
  }
`;

export default OurMission;
